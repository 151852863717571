import React from "react";
import { Background, Button, Container } from "../../components/Core";
import css from "./Dekujeme.module.scss";

const Dekujeme = () => {
  return (
    <Background color="light">
      <Container>
        <div className={css.dekujeme}>
          <div className={css.box}>
            <svg
              fill="#229970"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <h2>Děkujeme za váš zájem</h2>
            <p>
              Do druhého pracovního dne vás kontaktuje jeden z našich
              specialistů. Okno můžete nyni zavřít nebo pokračovat na hlavní
              stránku.
            </p>
            <Button as="link" url="/">
              Na úvodní stránku
            </Button>
          </div>
        </div>
      </Container>
    </Background>
  );
};

export default Dekujeme;
