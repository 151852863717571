import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../sections/Footer/Footer";
import Dekujeme from "../sections/Dekujeme/Dekujeme";
import Topbar from "../sections/Topbar/Topbar";

const dekujeme = () => {
  return (
    <>
      <Helmet>
        <title>Děkujeme! | Pronajmisitiskarnu.cz</title>
        <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-NSHS6PW');`}</script>
        <script>{`
          gtag('event', 'conversion', {'send_to': 'AW-871497707/MBQYCKjfqYACEOv_x58D'}); `}
        </script>

      </Helmet>
      <noscript>{`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NSHS6PW" height="0" width="0" style="display:none;visibility:hidden"></iframe>`}</noscript>
      <header>
        <Topbar />
      </header>
      <main>
        <Dekujeme />
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
};

export default dekujeme;
